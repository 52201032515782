import { styled, useStyletron } from 'baseui'
import { Checkbox } from 'baseui/checkbox'
import { Input } from 'baseui/input'
import { LabelSmall, LabelXSmall } from 'baseui/typography'
import _ from 'lodash'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { useGlobalKeyPress } from '../../../utils/hooks/useKeyPress'
import InfiniteList from '../../InfiniteList'
import MobileModal from '../../MobileModal'
import NoDataOverlay from '../../Table/NoDataOverlay'

import Button from '../../Buttons/Button'
import { BUTTON_KIND } from '../../Buttons/types'

const StyledRow = styled('div', ({ $theme }) => ({
    display: 'flex',
    paddingTop: $theme.sizing.scale300,
    paddingBottom: $theme.sizing.scale300,
    paddingLeft: $theme.sizing.scale600,
    paddingRight: $theme.sizing.scale600,
    alignItems: 'center',
    cursor: 'pointer'
}))

export interface IItemSelectionModal<T extends { id: string }> {
    testId?: string
    asModal?: boolean
    open?: boolean
    selectedItems: Record<string, T>
    items: T[]
    isMoreItemsAvailable: boolean
    loading: boolean
    searchQuery?: string
    onSubmit: () => void
    onItemSelectionChanged: (selectedItemIds: Record<string, T>) => void
    onSearchChanged?: (searchQuery: string) => void
    requestMoreItems: () => Promise<void>
    renderItem: (item: T) => { title: string; description: string }
    options: {
        selectionType?: 'single' | 'multi'
        submitButtonText?: string
        cancelButtonText?: string
        searchPlaceholder?: string
        modelName?: string
        minimumSelectionCount?: number
        showSelectAll?: boolean
        hideCheckboxes?: boolean
        markSelected?: boolean
    }
}

const ItemSelectionModal = <T extends { id: string }>({
    testId,
    asModal = true,
    selectedItems,
    items,
    isMoreItemsAvailable,
    loading,
    searchQuery,
    onSubmit,
    onItemSelectionChanged,
    onSearchChanged,
    requestMoreItems,
    renderItem,
    options: { selectionType = 'multi', searchPlaceholder, modelName, showSelectAll, hideCheckboxes, markSelected }
}: IItemSelectionModal<T>) => {
    const [css, theme] = useStyletron()
    const { t } = useTranslation()

    const onSearchChangedDebounced = React.useCallback(onSearchChanged ? _.debounce(onSearchChanged, 200) : () => {}, [
        onSearchChanged
    ])

    const isHoveringListItem = React.useRef(false)
    const isHoveringListItemId = React.useRef<string | null>(null)
    const [multiSelectionStart, setMultiSelectionStart] = React.useState<number | undefined>(undefined)
    const [multiSelectionEnd, setMultiSelectionEnd] = React.useState<number | undefined>(undefined)

    const multiSelectionLow =
        multiSelectionStart !== undefined && multiSelectionEnd !== undefined
            ? Math.min(multiSelectionStart, multiSelectionEnd)
            : undefined
    const multiSelectionHigh =
        multiSelectionStart !== undefined && multiSelectionEnd !== undefined
            ? Math.max(multiSelectionStart, multiSelectionEnd)
            : undefined

    const [shiftActive, setShiftActive] = React.useState(false)
    useGlobalKeyPress('Shift', {
        useKey: true,
        callbacks: {
            onKeyDown: () => {
                setShiftActive(true)
                if (!isHoveringListItem.current) {
                    setMultiSelectionEnd(undefined)
                }
            },
            onKeyUp: () => {
                setShiftActive(false)
            }
        }
    })

    const onClick = (item: T, index: number) => {
        const checked = !!selectedItems[item.id]

        const selection = document.getSelection()
        if (selection) {
            selection.removeAllRanges()
        }

        const selectedItemIdsCopy = { ...selectedItems }
        if (selectionType === 'multi') {
            if (shiftActive && multiSelectionLow !== undefined && multiSelectionHigh !== undefined) {
                for (const item of items.slice(multiSelectionLow, multiSelectionHigh + 1)) {
                    selectedItemIdsCopy[item.id] = item
                }
            } else {
                if (!checked) {
                    selectedItemIdsCopy[item.id] = item
                } else {
                    delete selectedItemIdsCopy[item.id]
                }

                setMultiSelectionStart(!checked ? index : undefined)
            }
        } else {
            Object.keys(selectedItemIdsCopy).forEach((key) => {
                delete selectedItemIdsCopy[key]
            })

            selectedItemIdsCopy[item.id] = item

            onSubmit()
        }

        onItemSelectionChanged(selectedItemIdsCopy)
    }

    const content = (
        <div
            className={css({
                display: 'flex',
                height: 'var(--vh, 100vh)',
                flexDirection: 'column',
                borderRadius: theme.sizing.scale800,
                [theme.mediaQuery.large]: { height: '60vh' }
            })}
        >
            {onSearchChanged && (
                <div>
                    <Input
                        startEnhancer={<i className="material-icons">search</i>}
                        value={searchQuery}
                        placeholder={searchPlaceholder || t('common.action.search')}
                        data-autofocus
                        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                            onSearchChangedDebounced(e.target.value)
                        }
                        overrides={{
                            Input: {
                                props: {
                                    'data-testid': !!testId && `${testId}/search`
                                }
                            },
                            Root: {
                                style: {
                                    border: 'none !important',
                                    paddingTop: theme.sizing.scale300,
                                    paddingBottom: theme.sizing.scale300
                                }
                            }
                        }}
                    />
                </div>
            )}
            <div className={css({ position: 'relative', flex: 1, overflow: 'hidden' })}>
                {items.length > 0 ? (
                    <InfiniteList
                        hasNextPage={isMoreItemsAvailable}
                        loadNextPage={requestMoreItems}
                        rowHeight={50}
                        list={items}
                        applyDarkScrollbar
                        renderItem={({ key, item, style, index }) => {
                            const multiSelectActive =
                                shiftActive &&
                                multiSelectionLow !== undefined &&
                                multiSelectionHigh !== undefined &&
                                index >= multiSelectionLow &&
                                index <= multiSelectionHigh

                            const { title, description } = renderItem(item)

                            return (
                                <div
                                    key={key}
                                    onClick={() => {
                                        onClick(item, index)
                                    }}
                                    onMouseEnter={() => {
                                        isHoveringListItem.current = true
                                        isHoveringListItemId.current = item.id
                                        setMultiSelectionEnd(index)
                                    }}
                                    onMouseLeave={() => {
                                        isHoveringListItem.current = false
                                        isHoveringListItemId.current = null
                                    }}
                                    style={{ ...style, background: multiSelectActive ? 'rgba(0,0,0,0.1)' : undefined }}
                                    data-testid={!!testId && `${testId}/entry`}
                                >
                                    <StyledRow>
                                        {!hideCheckboxes ? (
                                            <Checkbox
                                                checked={!!selectedItems[item.id]}
                                                overrides={{
                                                    Root: {
                                                        props: {
                                                            'data-testid':
                                                                !!testId && `${testId}/entry/checkbox/${index}`
                                                        },
                                                        style: {
                                                            paddingRight: theme.sizing.scale600,
                                                            borderColor: '#CBCBCB !important'
                                                        }
                                                    },
                                                    Checkmark: {
                                                        style: {
                                                            borderColor: '#CBCBCB !important',
                                                            borderWidth: `${theme.sizing.scale0} !important`
                                                        },
                                                        props: {
                                                            'data-testid':
                                                                !!testId &&
                                                                `${testId}/entry/checkbox/${index}/checkmark`
                                                        }
                                                    }
                                                }}
                                            />
                                        ) : undefined}
                                        <div
                                            className={css({
                                                flex: 1,
                                                minWidth: 0
                                            })}
                                        >
                                            <LabelSmall
                                                className={css({
                                                    maxLines: 1,
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    ...(markSelected
                                                        ? {
                                                              ':hover': {
                                                                  color: theme.colors.primary
                                                              },
                                                              ...(selectedItems[item.id] ||
                                                              isHoveringListItemId.current === item.id
                                                                  ? { color: theme.colors.primary }
                                                                  : {})
                                                          }
                                                        : {})
                                                })}
                                                data-testid={!!testId && `${testId}/entry/title`}
                                            >
                                                {title}
                                            </LabelSmall>
                                            <LabelXSmall
                                                className={css({
                                                    maxLines: 1,
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    ...(markSelected
                                                        ? {
                                                              ':hover': {
                                                                  color: theme.colors.primary
                                                              }
                                                          }
                                                        : {})
                                                })}
                                                color={
                                                    markSelected &&
                                                    (selectedItems[item.id] || isHoveringListItemId.current === item.id)
                                                        ? theme.colors.primary
                                                        : 'contentTertiary'
                                                }
                                                data-testid={!!testId && `${testId}/entry/description`}
                                            >
                                                {description}
                                            </LabelXSmall>
                                        </div>
                                    </StyledRow>
                                </div>
                            )
                        }}
                    />
                ) : (
                    <NoDataOverlay model={modelName} loading={loading} />
                )}
            </div>
            <div
                className={css({
                    display: 'flex',
                    paddingBlock: theme.sizing.scale600,
                    paddingLeft: theme.sizing.scale600,
                    paddingRight: theme.sizing.scale600,
                    flexDirection: 'row'
                })}
            >
                {selectionType === 'multi' && !Object.keys(selectedItems || {}).length && showSelectAll ? (
                    <div className={css({ textAlign: 'center', flex: 1 })}>
                        <Button
                            $kind={BUTTON_KIND.ghost}
                            onClick={() => {
                                const newSelection: Record<string, T> = {}
                                for (const item of items) {
                                    newSelection[item.id] = item
                                }
                                onItemSelectionChanged(newSelection)
                            }}
                            overrides={{
                                BaseButton: {
                                    props: {
                                        'data-testid': !!testId && `${testId}/select-all`
                                    },
                                    style: {
                                        flex: 1
                                    }
                                }
                            }}
                        >
                            {t('common.button.selectAll')}
                        </Button>
                    </div>
                ) : undefined}
                {selectionType === 'multi' && Object.keys(selectedItems || {}).length ? (
                    <div className={css({ textAlign: 'center', flex: 1 })}>
                        <Button
                            $kind={BUTTON_KIND.ghost}
                            onClick={() => onItemSelectionChanged({})}
                            overrides={{
                                BaseButton: {
                                    props: {
                                        'data-testid': !!testId && `${testId}/clear-selection`
                                    }
                                }
                            }}
                        >
                            {t('common.button.clearSelection')}
                        </Button>
                    </div>
                ) : undefined}
                <Button
                    onClick={onSubmit}
                    overrides={{
                        BaseButton: {
                            props: {
                                'data-testid': !!testId && `${testId}/done`
                            },
                            style: {
                                flex: 1
                            }
                        }
                    }}
                >
                    {t('common.button.done')}
                </Button>
            </div>
        </div>
    )

    return asModal ? (
        <MobileModal isOpen={true} closeable={false} onClose={onSubmit} atLeastFullHeight={false}>
            {content}
        </MobileModal>
    ) : (
        <div>{content}</div>
    )
}

export default ItemSelectionModal
